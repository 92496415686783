import leak from './leak.png';
import fire from './fire.png';
import security from './security.png';

import pulse from './pulse.png';
import system_objects from './system_objects.png';
import mesurements from './mesurements.png';
import invoices from './invoices.png';

import flat_search from './flat_search.png';
import crm from './crm.png';
import news from './news.png';
import paid_services from './paid_services.png';

import video from './video.png';
import intercom from './intercom.png';
import skud from './skud.png';
import clean_water from './tapWater.png';

import users from './users.png';
import buildings from './buildings.png';
import hub_settings from './hub_settings.png';

import vehicles from './vehicle.png';
import React from 'react';

export enum SidebarIconType {
    // emergency
    leak,
    fire,
    security,
    // askue
    pulse,
    systemObjects,
    measurements,
    invoices,

    // residents
    flatSearch,
    crm,
    newsAndVotings,
    paidServices,

    //engineering systems
    video,
    intercom,
    skud,
    cleanWater,

    //settings
    users,
    houseBuildings,
    hubSettings,

    vehicle,
}

const map = {
    [SidebarIconType.leak]: leak,
    [SidebarIconType.fire]: fire,
    [SidebarIconType.security]: security,

    [SidebarIconType.pulse]: pulse,
    [SidebarIconType.systemObjects]: system_objects,
    [SidebarIconType.measurements]: mesurements,
    [SidebarIconType.invoices]: invoices,

    [SidebarIconType.flatSearch]: flat_search,
    [SidebarIconType.crm]: crm,
    [SidebarIconType.newsAndVotings]: news,
    [SidebarIconType.paidServices]: paid_services,

    [SidebarIconType.video]: video,
    [SidebarIconType.intercom]: intercom,
    [SidebarIconType.skud]: skud,
    [SidebarIconType.cleanWater]: clean_water,

    [SidebarIconType.users]: users,
    [SidebarIconType.houseBuildings]: buildings,
    [SidebarIconType.hubSettings]: hub_settings,

    [SidebarIconType.vehicle]: vehicles,
};

interface IProps {
    type: SidebarIconType;
    opacity?: number;
}

const SidebarIcon = (props: IProps) => {
    const src = map[props.type];

    return <img src={src} alt='' />;
};
export default SidebarIcon;
