import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { Camera, ExtendedCamera } from '../services/cameras/models';
import { request } from '../apiCommandsExecutor';
import rootStore from './index';
import {
    CreateCameraRequest,
    GetCamerasResponse,
    GetExtendedCamerasRequest,
    GetExtendedCamerasResponse,
    UpdateCameraRequest,
} from '../services/cameras/requestResponses';

export enum CamerasForm {
    CreateForm = 'CreateForm',
    DeleteForm = 'DeleteForm',
}

class CamerasStore {
    formsState: { [key in CamerasForm]: boolean } = {
        CreateForm: false,
        DeleteForm: false,
    };

    isLoading: boolean = false;
    cameras: Camera[] = [];
    chosenCamera: Camera | null = null;
    extendedCameras: ExtendedCamera[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    @action
    setChosenCamera(v: Camera | null) {
        this.chosenCamera = v;
    }

    async loadCameras() {
        this.setLoading(true);
        const result = await request<GetCamerasResponse>('/cameras', {
            params: {
                placeId: rootStore.placesStore.currentPlace?.id,
            },
        });

        if (result) {
            runInAction(() => {
                this.cameras = result.cameras;
            });
        }
        this.setLoading(false);
    }

    async loadExtendedCameras(params: GetExtendedCamerasRequest) {
        this.setLoading(true);
        const result = await request<GetExtendedCamerasResponse>(
            '/cameras/extended',
            {
                params,
            },
        );

        if (result) {
            runInAction(() => {
                this.extendedCameras = result.cameras;
            });
        }

        this.setLoading(false);
    }

    @computed
    getCamera(cameraId: string) {
        return this.cameras.find((camera) => camera.id == cameraId);
    }

    async addCamera(params: CreateCameraRequest) {
        const result = await request<Camera>('/cameras', {
            method: 'POST',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadCameras();
        }
    }

    async updateCamera(cameraId: string, params: UpdateCameraRequest) {
        const result = await request<Camera>(`/cameras/${cameraId}`, {
            method: 'PUT',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadCameras();
        }
    }

    async deleteCamera(cameraId: string) {
        const result = await request<boolean>(`/cameras/${cameraId}`, {
            method: 'DELETE',
        });

        if (result) {
            this.loadCameras();
        }
    }

    @action
    openForm(type: keyof typeof CamerasForm) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof CamerasForm) {
        this.formsState[type] = false;
    }
}

export default CamerasStore;
