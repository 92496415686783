import { Shadows } from '@material-ui/core/styles/shadows';

function createShadow(px: number) {
    return `0 0 ${px}px 0 rgba(0, 0, 0, 0.09)`;
}

const shadows: Shadows = [
    'none',
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
    createShadow(24),
];

export default shadows;
