import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { ExtendedCameraItem } from './ExtendedCameraItem';

const ExtendedCamerasList: React.FC = () => {
    const { camerasStore } = useStores();

    const extendedCameras = camerasStore.extendedCameras;

    return (
        <Grid container spacing={5} xs={12} md={8} lg={6}>
            {extendedCameras.map((extendedCamera) => (
                <Grid item xs={12}>
                    <ExtendedCameraItem extendedCamera={extendedCamera} />
                </Grid>
            ))}
        </Grid>
    );
};

export default observer(ExtendedCamerasList);
