import React, { ExoticComponent, lazy } from 'react';
import { Users } from 'react-feather';
import { RouteInfoType } from '../../types/types';
import AuthLayout from '../../layouts/Auth';

const SignInPage = lazy(() => import('./pages/SignIn')) as ExoticComponent;
const SignUpPage = lazy(() => import('./pages/SignUp')) as ExoticComponent;
const authRoutes = {
    id: 'auth',
    path: '/auth',
    name: 'Авторизация',
    icon: <Users />,
    hide: true,
    component: <AuthLayout />,
    routes: [
        {
            path: 'sign-in',
            name: 'Вход',
            component: <SignInPage />,
        },
        {
            path: 'sign-up',
            name: 'Регистрация',
            component: <SignUpPage />,
        },
    ],
} as RouteInfoType;

export default authRoutes;
