import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import React, { useEffect, useMemo } from 'react';
import {
    Button,
    FlexRowSpaceBetween,
    Paper,
    Typography,
} from '../../../components/libs';
import { Add as AddIcon } from '@material-ui/icons';
import { HubsForms } from '../../../stores/hubs';
import HubForm from './forms/HubForm';
import { Grid } from '@material-ui/core';

const HubsSettings = () => {
    const { generalStore, hubsStore, placesStore } = useStores();
    const placesById = placesStore.places.reduce((map, item) => {
        map[item.id] = item;
        return map;
    }, {});

    const headerButton = useMemo(
        () => [
            <Button
                variant='contained'
                color='primary'
                mr={4}
                key='createHubBtn'
                onClick={() => {
                    hubsStore.openForm(HubsForms.CreateForm);
                }}
            >
                <AddIcon />
                Добавить
            </Button>,
        ],
        [],
    );

    const closeForm = (type: 'CreateForm' | 'EditForm') => {
        hubsStore.setHubToEdit(null);
        hubsStore.closeForm(type);
    };

    useEffect(() => {
        hubsStore.loadHubs();
        placesStore.loadPlaces();
        generalStore.setPageTitle('Хабы', headerButton);
    }, []);

    return (
        <>
            <Grid style={{ marginTop: '5px' }} container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {hubsStore.hubs.map((hub, index) => (
                        <Paper p={4} mb={3} key={index}>
                            <FlexRowSpaceBetween>
                                <div>
                                    <Typography
                                        variant='body2'
                                        style={{ color: '#6B6B6B' }}
                                    >
                                        {hub.uuid}
                                    </Typography>
                                    <Typography
                                        style={{ fontWeight: 'bold' }}
                                        variant='h4'
                                    >
                                        {hub.title
                                            ? hub.title
                                            : 'Хаб для аналитики'}
                                        {hub.placeIds.length > 0
                                            ? ` (${hub.placeIds
                                                  .map(
                                                      (placeId) =>
                                                          placesById[placeId]
                                                              ?.data?.title ||
                                                          '',
                                                  )
                                                  .join(', ')})`
                                            : ''}
                                    </Typography>
                                </div>
                            </FlexRowSpaceBetween>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    marginTop: 10,
                                }}
                            >
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    onClick={() => {
                                        hubsStore.setHubToEdit(hub);
                                        hubsStore.openForm(HubsForms.EditForm);
                                    }}
                                    style={{ marginRight: 5 }}
                                >
                                    <span
                                        style={{
                                            marginLeft: '3px',
                                            fontSize: 'small',
                                        }}
                                    >
                                        Редактировать
                                    </span>
                                </Button>
                            </div>
                        </Paper>
                    ))}
                </Grid>
            </Grid>
            <HubForm
                open={
                    hubsStore.formsState.CreateForm ||
                    hubsStore.formsState.EditForm
                }
                onClose={closeForm}
            />
        </>
    );
};

export default observer(HubsSettings);
