import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { FormControl, Paper } from '../../../../../components/libs';
import { DatePicker } from '@material-ui/pickers';
import { useStores } from '../../../../../hooks';
import moment from 'moment';

const FiltersBlock: React.FC = () => {
    const { placesStore, analyticsStore } = useStores();
    const [sinceDt, setSinceDt] = useState(
        moment().subtract(7, 'days').toDate(),
    );
    const [tillDt, setTillDt] = useState(moment().toDate());

    useEffect(() => {
        if (sinceDt && tillDt && placesStore.currentPlace) {
            analyticsStore.loadAnalytics({
                placeId: placesStore.currentPlace.id,
                sinceDt: moment(sinceDt).startOf('day').toDate(),
                tillDt: moment(tillDt).endOf('day').toDate(),
            });
        }
    }, [sinceDt, tillDt, placesStore.currentPlace?.id]);

    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                }}
            >
                <FormControl m={4}>
                    <DatePicker
                        inputVariant={'outlined'}
                        format={'dd.MM.yyyy'}
                        label='С'
                        value={sinceDt}
                        onChange={(date: Date) => {
                            setSinceDt(date);
                        }}
                    />
                </FormControl>
                <FormControl m={4}>
                    <DatePicker
                        inputVariant={'outlined'}
                        format={'dd.MM.yyyy'}
                        label='По'
                        value={tillDt}
                        onChange={(date: Date) => {
                            setTillDt(date);
                        }}
                    />
                </FormControl>
            </Box>
        </Paper>
    );
};

export default observer(FiltersBlock);
