import styled from 'styled-components';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';

import {
    Breadcrumbs as MuiBreadcrumbs,
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    CircularProgress as MuiCircularProgress,
    Divider as MuiDivider,
    FormControl as MuiFormControl,
    Paper as MuiPaper,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from '@material-ui/core';
import { spacing, SpacingProps } from '@material-ui/system';

import { green, orange, red } from '@material-ui/core/colors';
import React from 'react';

export const Card = styled(MuiCard)(spacing);

export const CardContent = styled(MuiCardContent)`
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

interface TypographyPropsType extends SpacingProps {
    component?: string;
}

export const Typography = styled(MuiTypography)<TypographyPropsType>(spacing);

export const Divider = styled(MuiDivider)(spacing);

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Paper = styled(MuiPaper)(spacing);

export interface ChipPropstype extends SpacingProps {
    shipped?: boolean;
    processing?: boolean;
    cancelled?: boolean;
}

export const Chip = styled(MuiChip)<ChipPropstype>`
    ${spacing};

    background: ${(props) => props.shipped && green[500]};
    background: ${(props) => props.processing && orange[700]};
    background: ${(props) => props.cancelled && red[500]};
    color: ${(props) => props.theme.palette.common.white};
`;

export const Spacer = styled.div(spacing);

export const ToolbarTitle = styled.div`
    min-width: 300px;
`;

export const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

export const Button = styled(MuiButton)(spacing);

export const DangerButton = styled(Button)`
    color: ${(props) => props.theme.palette.error.main};
`;
export const CircularProgress = styled(MuiCircularProgress)(spacing);

export const FormControl = styled(MuiFormControl)<SpacingProps>(spacing);

export const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export const locale = 'ru';

const Alert = styled(MuiAlert)(spacing);

interface IAlertProps {
    title: string;
    content: JSX.Element | string;
}

export const ErrorAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='error'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const WarningAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='warning'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const InfoAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='info'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const SuccessAlert = (props: IAlertProps) => {
    return (
        <Alert mb={4} severity='success'>
            <AlertTitle>{props.title}</AlertTitle>
            {props.content}
        </Alert>
    );
};

export const FlexRowSpaceBetween = (props: { children: any }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.children}
        </div>
    );
};

export const FlexRowSpaceAround = (props: { children: any }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {props.children}
        </div>
    );
};

export const FlexRow = (props: { children: any }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {props.children}
        </div>
    );
};

export const changeEventHandlerDecorator = (fn: (value: any) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        fn(e.target.value);
    };
};
