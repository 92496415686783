import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import PlaceSelect from '../../../../components/PlaceSelect';
import {
    Button,
    FlexRowSpaceBetween,
    Paper,
    Typography,
} from '../../../../components/libs';
import { VehiclesForms } from '../../../../stores/vehicles';
import Loader from '../../../../components/Loader';
import { Grid, IconButton } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { CamerasForm } from '../../../../stores/cameras';
import CreateCameraForm from './forms/CreateCameraForm';

const CamerasPage: React.FC = () => {
    const { generalStore, camerasStore, placesStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        generalStore.setPageTitle('Камеры', [
            <Button
                variant='contained'
                color='primary'
                onClick={() => camerasStore.openForm(VehiclesForms.CreateForm)}
                key={'createBtn'}
            >
                Добавить камеру
            </Button>,
            <PlaceSelect />,
        ]);
    }, []);

    useEffect(() => {
        if (!placesStore.currentPlace) return;
        camerasStore.loadCameras();
    }, [placesStore.currentPlace?.id]);

    return (
        <>
            {camerasStore.isLoading && <Loader />}
            {!camerasStore.isLoading && (
                <Grid style={{ marginTop: '5px' }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {camerasStore.cameras.map((camera, index) => (
                            <Paper
                                key={index}
                                p={5}
                                mb={3}
                                onClick={() =>
                                    navigate(`${camera.id}/information`)
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <FlexRowSpaceBetween>
                                    <div>
                                        <Typography
                                            style={{ color: '#6B6B6B' }}
                                            variant='body2'
                                        >
                                            {camera.externalId}
                                        </Typography>
                                        <Typography
                                            style={{ fontWeight: 'bold' }}
                                            variant='h5'
                                        >
                                            {camera.title}
                                        </Typography>
                                    </div>
                                    <div>
                                        <IconButton color={'primary'}>
                                            <ChevronRight
                                                style={{
                                                    width: '30px',
                                                    height: '30px',
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </FlexRowSpaceBetween>
                            </Paper>
                        ))}
                    </Grid>
                </Grid>
            )}
            <CreateCameraForm
                open={camerasStore.formsState.CreateForm}
                onClose={() => camerasStore.closeForm(CamerasForm.CreateForm)}
            />
        </>
    );
};

export default observer(CamerasPage);
