import moment from 'moment/moment';
import { Video } from '../services/videos/models';

export const getWorkTimeStr = (value: number) => {
    const timeInMinutes = value / 60;

    if (timeInMinutes >= 60) {
        return `${Math.floor(timeInMinutes / 60)} ч ${(
            timeInMinutes % 60
        ).toFixed(0)} мин`;
    } else {
        return `${timeInMinutes.toFixed(0)} мин`;
    }
};

export const getVideoFileName = (video: Video) => {
    const startAt = moment(video.startAt).add(3, 'hours');
    const endAt = moment(video.endAt).add(3, 'hours');

    return `${startAt.format('HH:mm')} - ${endAt.format('HH:mm')}`;
};

export const getTimeStr = (dt: Date) => {
    return moment(dt).add(3, 'hours').format('HH:mm:ss');
};

export const getWorkStateStr = (state: 'work' | 'idle') => {
    return {
        work: 'Работает',
        idle: 'Простаивает',
    }[state];
};

export const getDirectionStr = (state: string) => {
    return {
        in: 'Въезд',
        out: 'Выезд',
        unknown: 'Неизвестно',
    }[state];
};
