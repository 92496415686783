import React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import { FormControl } from '../libs';
import { InputLabel } from '@material-ui/core';

interface StyledSelectProps extends SelectProps {
    value: string | number | string[] | number[];
    defaultValue?: string | number | string[] | number[];
    options: JSX.Element[];
    handleChange: (e: React.ChangeEvent<{ name: string; value }>) => unknown;
    selectName: string;
    label: string;
    my: number;
    variant?: 'filled' | 'outlined' | 'standard';
    isDefaultInput?: boolean;
    fullWidth?: boolean;
    customInputStyles?: object;
}

const StyledSelect: React.FC<StyledSelectProps> = ({
    value,
    defaultValue,
    handleChange,
    options,
    selectName,
    label,
    my,
    variant,
    isDefaultInput,
    fullWidth,
    customInputStyles,
    ...props
}) => (
    <FormControl
        my={my}
        fullWidth={fullWidth ?? true}
        variant={variant || 'outlined'}
    >
        <InputLabel htmlFor={selectName}>{label}</InputLabel>
        <Select
            name={selectName}
            value={value || ''}
            defaultValue={defaultValue}
            onChange={handleChange}
            inputProps={{
                name: selectName,
                id: selectName,
            }}
            input={
                !isDefaultInput && (
                    <OutlinedInput
                        name={selectName}
                        id={selectName}
                        labelWidth={130}
                        style={customInputStyles}
                    />
                )
            }
            {...props}
        >
            {options}
        </Select>
    </FormControl>
);

export default StyledSelect;
