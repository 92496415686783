import React, { useEffect, useState } from 'react';
// import { AccountRole } from '../services/accounts/types';
import { observer } from 'mobx-react';
import { useStores } from '../hooks';
import { Outlet, useNavigate } from 'react-router-dom';

const SecurityLayout: React.FC = () => {
    const [isReady, setIsReady] = useState(false);
    const { authStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        authStore.loadUserData().then(() => setIsReady(true));
    }, []);

    useEffect(() => {
        if (!isReady) return;
        const { isAuthorized } = authStore;
        //
        // const isAdmin = isAuthorized && currentUser.role === AccountRole.Admin;
        // const isMaintainer =
        //     isAuthorized && currentUser.role === AccountRole.Maintainer;
        // const isManager =
        //     isAuthorized && currentUser.role === AccountRole.Manager;
        // const isSupport =
        //     isAuthorized && currentUser.role === AccountRole.Support;
        // const isConcierge =
        //     isAuthorized && currentUser.role === AccountRole.Concierge;
        if (!isAuthorized) {
            navigate('/auth/sign-in');
        } else if (window.location.pathname === '/') {
            navigate('/analytics');
        }
        // if (
        //     (isMaintainer || isAdmin || isManager || isConcierge) &&
        //     window.location.pathname === '/'
        // ) {
        //     navigate('/maintainer');
        // } else if (isManager && window.location.pathname === '/') {
        //     navigate('/manager');
        // } else if (isSupport && window.location.pathname === '/') {
        //     navigate('/support');
        // } else if (isConcierge && window.location.pathname === '/') {
        //     navigate('/concierge');
        // }
    }, [isReady, authStore.isAuthorized]);

    if (!isReady) {
        return null;
    }
    return <Outlet />;
};

export default observer(SecurityLayout);
