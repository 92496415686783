import React from 'react';
import { ExtendedCamera } from '../../../../../services/cameras/models';
import { Paper, Typography } from '../../../../../components/libs';
import { Grid } from '@material-ui/core';
import { Vehicle as VehicleIcon } from '../../../../../icons/Vehicle';
import VideoIconInfo from './VideoIconInfo';
import { WorkTime as WorkTimeIcon } from '../../../../../icons/WorkTime';
import { Sleep as SleepIcon } from '../../../../../icons/Sleep';
import { Vehicle } from '../../../../../services/vehicles/models';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../../../../hooks';
import { getWorkTimeStr } from 'utils';

interface ExtendedCameraItemProps {
    extendedCamera: ExtendedCamera;
}

export const ExtendedCameraItem: React.FC<ExtendedCameraItemProps> = ({
    extendedCamera,
}) => {
    const navigate = useNavigate();
    const { camerasStore } = useStores();

    const getTotalTimeStr = (state: 'work' | 'idle') => {
        const totalSeconds = extendedCamera.monitoringData
            .filter((item) => item.state == state)
            .map((item) => item.secondsInState)
            .reduce((sum, item) => {
                return sum + item;
            }, 0);

        return getWorkTimeStr(totalSeconds);
    };

    const vehiclesByClass: {
        [key: string]: {
            vehicle: Vehicle;
            work: number;
            idle: number;
        };
    } = {};
    extendedCamera.monitoringData.forEach((item) => {
        if (!(item.recognitionClass in vehiclesByClass)) {
            vehiclesByClass[item.recognitionClass] = {
                vehicle: item.vehicle,
                work: 0,
                idle: 0,
            };
        }

        vehiclesByClass[item.recognitionClass][item.state] +=
            item.secondsInState;
    });

    const vehiclesWithStates = Object.values(vehiclesByClass);

    return (
        <Paper
            p={5}
            onClick={() => {
                camerasStore.setChosenCamera(extendedCamera.camera);
                navigate(`${extendedCamera.camera.id}`);
            }}
        >
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography style={{ color: '#6B6B6B' }} variant='body2'>
                        {extendedCamera.camera.externalId}
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }} variant='h5'>
                        {extendedCamera.camera.title}
                    </Typography>
                </Grid>
                {extendedCamera.videoCounter == 0 && (
                    <Grid item xs={12} md={4}>
                        <Typography
                            style={{ color: '#6B6B6B' }}
                            variant='body2'
                        >
                            Нет обработанных видео
                        </Typography>
                    </Grid>
                )}
                {extendedCamera.videoCounter > 0 && (
                    <>
                        <Grid item xs={12} md={4}>
                            <VideoIconInfo
                                title={extendedCamera.monitoringData.length.toString()}
                                description={'Кол-во'}
                                icon={
                                    <VehicleIcon
                                        color={'#2a73ff'}
                                        fill={'#2a73ff'}
                                        width={25}
                                        height={25}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <VideoIconInfo
                                title={getTotalTimeStr('work')}
                                description={'Работа'}
                                icon={
                                    <WorkTimeIcon
                                        // color={'#2a73ff'}
                                        // fill={'#2a73ff'}
                                        stroke={'#2a73ff'}
                                        width={25}
                                        height={25}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <VideoIconInfo
                                title={getTotalTimeStr('idle')}
                                description={'Простой'}
                                icon={
                                    <SleepIcon
                                        color={'#2a73ff'}
                                        fill={'#2a73ff'}
                                        width={25}
                                        height={25}
                                    />
                                }
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            {extendedCamera.inCounter > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Въехало: {extendedCamera.inCounter}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {extendedCamera.outCounter > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Выехало: {extendedCamera.outCounter}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {vehiclesWithStates.length > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>Техника:</Typography>
                    </Grid>
                    {vehiclesWithStates.map((item, index) => (
                        <Grid item xs={12}>
                            <Typography>
                                {index + 1}. {item.vehicle.title} (Работа:{' '}
                                {getWorkTimeStr(item.work)}, Простой:{' '}
                                {getWorkTimeStr(item.idle)})
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Paper>
    );
};
