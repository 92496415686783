import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Button, Paper } from '../../../components/libs';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useStores } from '../../../hooks';
import { FadeInOutVertical } from '../../../components/Animated';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@material-ui/core';
import { EnhancedTableHead, HeadCell } from '../../../components/TableHelpers';
import { PlacesForms } from '../../../stores/places';
import PlaceForm from '../forms/PlaceForm';

const placesHeadCells: Array<HeadCell> = [
    { id: 'number', alignment: 'left', label: '#' },
    { id: 'title', alignment: 'left', label: 'Название' },
    { id: 'description', alignment: 'left', label: 'Описание' },
    { id: 'actions', alignment: 'right', label: 'Действия' },
];

const PlacesSettings: React.FC = () => {
    const { generalStore, placesStore } = useStores();
    const headerButton = useMemo(
        () => [
            <Button
                variant='contained'
                color='primary'
                mr={4}
                key='createPlaceBtn'
                onClick={() => {
                    placesStore.openForm(PlacesForms.CreateForm);
                }}
            >
                <AddIcon />
                Добавить
            </Button>,
        ],
        [],
    );

    useEffect(() => {
        placesStore.loadPlaces();
        generalStore.setPageTitle('Площадки', headerButton);
    }, []);

    const closeForm = (type: 'CreateForm' | 'EditForm') => {
        placesStore.setPlaceToEdit(null);
        placesStore.closeForm(type);
    };

    return (
        <FadeInOutVertical show={true}>
            <Paper>
                <TableContainer>
                    <Table
                        aria-labelledby='tableTitle'
                        size={'medium'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead cells={placesHeadCells} />
                        <TableBody>
                            {placesStore.places.map((place, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`${place.id}-${index}`}
                                    >
                                        <TableCell align='left'>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {place.data.title}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {place.data.description}
                                        </TableCell>
                                        <TableCell padding='none' align='right'>
                                            <IconButton
                                                aria-label='edit'
                                                onClick={() => {
                                                    placesStore.setPlaceToEdit(
                                                        place,
                                                    );
                                                    placesStore.openForm(
                                                        PlacesForms.EditForm,
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PlaceForm
                    open={
                        placesStore.formsState.CreateForm ||
                        placesStore.formsState.EditForm
                    }
                    onClose={closeForm}
                />
            </Paper>
        </FadeInOutVertical>
    );
};

export default observer(PlacesSettings);
