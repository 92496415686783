import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStores } from '../../../../hooks';
import PlaceSelect from '../../../../components/PlaceSelect';
import { FadeInOutVertical } from '../../../../components/Animated';
import { Box } from '@material-ui/core';
import FiltersBlock from './components/FiltersBlock';
import { Loader } from 'react-feather';
import Reports from './components/Reports';

const ReportsPage: React.FC = () => {
    const { generalStore, analyticsStore } = useStores();

    useEffect(() => {
        generalStore.setPageTitle('Отчёты', [<PlaceSelect />]);
    }, []);

    return (
        <FadeInOutVertical show={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gridGap: 20,
                }}
            >
                <FiltersBlock />
                {analyticsStore.isLoading && <Loader />}
                {!analyticsStore.isLoading && <Reports />}
            </Box>
        </FadeInOutVertical>
    );
};

export default observer(ReportsPage);
