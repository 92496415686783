import React from 'react';
import { animated, useSpring } from 'react-spring/web.cjs';

export const FadeInOutVertical: React.FC<{
    show: boolean;
    children: JSX.Element;
}> = (props) => {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(0,20px,0)' },
        to: {
            opacity: props.show ? 1 : 0,
            transform: `translate3d(0, ${props.show ? 0 : 20}px,0)`,
        },
    });

    return <animated.div style={style}>{props.children}</animated.div>;
};
