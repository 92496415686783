import { Box } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../../../../components/libs';

interface VideoIconInfo {
    title: string;
    description: string;
    icon: any;
}

const VideoIconInfo: React.FC<VideoIconInfo> = ({
    title,
    description,
    icon,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gridGap: 5,
            }}
        >
            <Box
                sx={{
                    // @ts-ignore
                    backgroundColor: '#EAF1FF',
                    padding: '8px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                }}
            >
                {icon}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ color: '#7D7D7D' }}>
                    {description}
                </Typography>
                <Typography style={{ fontWeight: 700 }}>{title}</Typography>
            </Box>
        </Box>
    );
};

export default VideoIconInfo;
