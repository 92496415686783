import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    Place,
    PlaceAvailableModules,
    PlaceData,
} from '../services/places/models';
import { request } from '../apiCommandsExecutor';
import { GetPlacesResponse } from '../services/places/requestResponses';

export enum PlacesForms {
    CreateForm = 'CreateForm',
    EditForm = 'EditForm',
}

class PlacesStore {
    formsState: { [key in PlacesForms]: boolean } = {
        CreateForm: false,
        EditForm: false,
    };

    places: Place[] = [];
    placeToEdit: Place | null = null;
    currentPlace: Place | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    init() {
        this.loadPlaces();
    }

    async loadPlaces() {
        const response = await request<GetPlacesResponse>('/places');

        if (response) {
            runInAction(() => {
                this.places = response.places;
                if (localStorage.placeId) {
                    const placeId = JSON.parse(localStorage.getItem('placeId'));
                    if (this.places.map((i) => i.id).includes(placeId)) {
                        this.setCurrentPlace(placeId);
                    } else {
                        this.currentPlace =
                            this.places.length > 0 ? this.places[0] : null;
                        localStorage.setItem(
                            'placeId',
                            JSON.stringify(this.currentPlace?.id || null),
                        );
                    }
                } else {
                    this.currentPlace =
                        this.places.length > 0 ? this.places[0] : null;
                    localStorage.setItem(
                        'placeId',
                        JSON.stringify(this.currentPlace?.id || null),
                    );
                }
            });
        }
    }

    @action
    openForm(type: keyof typeof PlacesForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof PlacesForms) {
        this.formsState[type] = false;
    }

    @action
    setPlaceToEdit(place: Place | null) {
        this.placeToEdit = place;
    }

    @action
    setCurrentPlace(placeId: string | null) {
        const currentPlace = this.places.find((place) => place.id == placeId);
        this.currentPlace = currentPlace;
    }

    async createPlace(
        data: PlaceData,
        availableModules: PlaceAvailableModules,
    ) {
        const result = await request<Place>('/places', {
            method: 'POST',
            data: {
                data,
                availableModules,
            },
        });

        if (result) {
            await this.loadPlaces();
        }
    }

    async updatePlace(
        placeId: string,
        data: PlaceData,
        availableModules: PlaceAvailableModules,
    ) {
        const result = await request<Place>(`/places/${placeId}`, {
            method: 'PUT',
            data: {
                data,
                availableModules,
            },
        });

        if (result) {
            await this.loadPlaces();
        }
    }
}

export default PlacesStore;
