import {
    GetVehiclesMonitoringAnalyticsRequest,
    GetVehiclesMonitoringAnalyticsResponse,
} from '../services/analytics/requestResponses';
import { VehicleMonitoringItem } from '../services/analytics/models';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { request } from '../apiCommandsExecutor';

class AnalyticsStore {
    isLoading: boolean = false;
    vehiclesMonitoringData: VehicleMonitoringItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    async loadAnalytics(params: GetVehiclesMonitoringAnalyticsRequest) {
        console.log('load analytics');
        this.setLoading(true);

        const result = await request<GetVehiclesMonitoringAnalyticsResponse>(
            '/analytics/monitoring',
            {
                params: params,
            },
        );

        if (result) {
            runInAction(() => {
                console.log('run in aciton');
                this.vehiclesMonitoringData = result.monitoringData;
            });
        }
        this.setLoading(false);
    }
}

export default AnalyticsStore;
