import React from 'react';

export const WorkTime: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg
            version='1.1'
            id='svg1425'
            width='682.66669'
            height='682.66669'
            viewBox='0 0 682.66669 682.66669'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <defs id='defs1429'>
                <clipPath clipPathUnits='userSpaceOnUse' id='clipPath1439'>
                    <path d='M 0,512 H 512 V 0 H 0 Z' id='path1437' />
                </clipPath>
            </defs>
            <g
                id='g1431'
                transform='matrix(1.3333333,0,0,-1.3333333,0,682.66667)'
            >
                <g id='g1433'>
                    <g id='g1435' clipPath='url(#clipPath1439)'>
                        <g id='g1441' transform='translate(164.0573,27.7596)'>
                            <path
                                d='m 0,0 c 88.709,-35.687 194.009,-17.59 265.891,54.292 87.522,87.522 95.309,224.586 23.359,320.923'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1443'
                            />
                        </g>
                        <g id='g1445' transform='translate(127.9967,45.8593)'>
                            <path
                                d='M 0,0 C 0.13,-0.079 0.26,-0.159 0.39,-0.238'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1447'
                            />
                        </g>
                        <g id='g1449' transform='translate(493.6667,406)'>
                            <path
                                d='M 0,0 H -43.667 V -43.667'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1451'
                            />
                        </g>
                        <g id='g1453' transform='translate(349.3738,483.6595)'>
                            <path
                                d='m 0,0 c -89.005,36.462 -195.053,18.558 -267.322,-53.711 -87.522,-87.522 -95.309,-224.586 -23.359,-320.923'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1455'
                            />
                        </g>
                        <g id='g1457' transform='translate(385.3275,465.3268)'>
                            <path
                                d='M 0,0 C -0.165,0.102 -0.33,0.204 -0.495,0.306'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1459'
                            />
                        </g>
                        <g id='g1461' transform='translate(18.75,104.6667)'>
                            <path
                                d='M 0,0 H 44.583 V 44.583'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1463'
                            />
                        </g>
                        <g id='g1465' transform='translate(231.9803,256.0001)'>
                            <path
                                d='m 0,0 c 0,-13.266 10.754,-24.02 24.02,-24.02 13.266,0 24.02,10.754 24.02,24.02 0,13.266 -10.754,24.02 -24.02,24.02 C 10.754,24.02 0,13.266 0,0 Z'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1467'
                            />
                        </g>
                        <g id='g1469' transform='translate(109.0976,345.5395)'>
                            <path
                                d='m 0,0 c -40.437,-66.221 -32.01,-153.872 25.28,-211.162 67.17,-67.17 176.075,-67.17 243.245,0 67.17,67.17 67.17,176.075 0,243.245 -57.253,57.253 -144.826,65.706 -211.032,25.36'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1471'
                            />
                        </g>
                        <g id='g1473' transform='translate(134.1139,377.3575)'>
                            <path
                                d='M 0,0 C -0.176,-0.176 -0.088,-0.088 -0.264,-0.265'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1475'
                            />
                        </g>
                        <g id='g1477' transform='translate(389.7728,255.8404)'>
                            <path
                                d='M 0,0 H -6.058'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1479'
                            />
                        </g>
                        <g id='g1481' transform='translate(122.2278,256.1598)'>
                            <path
                                d='M 0,0 H 6.058'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1483'
                            />
                        </g>
                        <g id='g1485' transform='translate(256.16,389.7726)'>
                            <path
                                d='M 0,0 V -6.058'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1487'
                            />
                        </g>
                        <g id='g1489' transform='translate(255.8406,122.2276)'>
                            <path
                                d='M 0,0 V 6.058'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1491'
                            />
                        </g>
                        <g id='g1493' transform='translate(239.0156,272.9848)'>
                            <path
                                d='M 0,0 -39.205,39.205'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1495'
                            />
                        </g>
                        <g id='g1497' transform='translate(272.985,272.9848)'>
                            <path
                                d='M 0,0 73.09,73.09'
                                fill={'none'}
                                strokeWidth={'20'}
                                strokeLinecap={'round'}
                                strokeLinejoin={'round'}
                                strokeMiterlimit={'10'}
                                strokeDasharray={'none'}
                                strokeOpacity={'1'}
                                id='path1499'
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
