import { TokenPair } from '../services/users/models';

export const getToken = () => {
    const tokenObj = localStorage.getItem('token');

    if (tokenObj) {
        return (JSON.parse(tokenObj) as TokenPair).authToken;
    } else {
        return '';
    }
};

export const saveTokenPair = (token: TokenPair) => {
    if (!token) return;
    localStorage.setItem('token', JSON.stringify(token));
};

export const removeTokenPair = () => {
    localStorage.removeItem('token');
};
