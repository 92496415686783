import React, { useEffect, useState } from 'react';
import { CardActions, Grid } from '@material-ui/core';
import {
    Button,
    Card,
    CardContent,
    changeEventHandlerDecorator,
    Paper,
    TextField,
} from '../../../../../../components/libs';
import { CamerasForm } from '../../../../../../stores/cameras';
import { useStores } from '../../../../../../hooks';
import { Camera } from '../../../../../../services/cameras/models';

interface EditCameraInfoProps {
    camera?: Camera;
}

export const EditCameraInfo: React.FC<EditCameraInfoProps> = ({ camera }) => {
    const { camerasStore, generalStore } = useStores();

    const [title, setTitle] = useState<string>('');
    const [externalId, setExternalId] = useState<string>('');
    const [videosSource, setVideosSource] = useState<string>('');
    const updateCamera = async () => {
        if (!camera) {
            return;
        }
        await camerasStore.updateCamera(camera.id, {
            title,
            externalId,
            videosSource,
        });
        generalStore.showSnackbar('Данные обновлены');
        camerasStore.loadCameras();
    };

    useEffect(() => {
        if (camera) {
            setTitle(camera.title);
            setExternalId(camera.externalId);
            setVideosSource(camera.videosSource);
        }
    }, [camera]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={6} xl={6}>
                <Card>
                    <Paper>
                        <CardContent>
                            <TextField
                                value={title}
                                id='cameraTitle'
                                variant='outlined'
                                color='primary'
                                style={{ marginTop: 10 }}
                                label='Название'
                                type={'text'}
                                fullWidth
                                onChange={changeEventHandlerDecorator(setTitle)}
                            />
                            <TextField
                                value={externalId}
                                id='cameraExternalId'
                                variant='outlined'
                                color='primary'
                                style={{ marginTop: 10 }}
                                label='Внешний идентификатор'
                                type={'text'}
                                fullWidth
                                onChange={changeEventHandlerDecorator(
                                    setExternalId,
                                )}
                            />
                            <TextField
                                value={videosSource}
                                id='cameraVideosSource'
                                variant='outlined'
                                color='primary'
                                style={{ marginTop: 10 }}
                                label='Адрес обработанных видео'
                                type={'text'}
                                fullWidth
                                onChange={changeEventHandlerDecorator(
                                    setVideosSource,
                                )}
                            />
                        </CardContent>
                    </Paper>
                    <CardActions
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color={'default'}
                            variant={'contained'}
                            onClick={() => {
                                camerasStore.openForm(CamerasForm.DeleteForm);
                            }}
                        >
                            Удалить
                        </Button>
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={updateCamera}
                            disabled={!title}
                        >
                            Сохранить
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};
