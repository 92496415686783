import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Button } from '../../../../../components/libs';
import { Camera } from '../../../../../services/cameras/models';

interface ConfirmDeleteCameraFormProps {
    open: boolean;
    onClose: () => void;
    camera: Camera;
}

const ConfirmDeleteCameraForm: React.FC<ConfirmDeleteCameraFormProps> = ({
    open,
    onClose,
    camera,
}) => {
    const { generalStore, camerasStore } = useStores();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        await camerasStore.deleteCamera(camera.id);
        generalStore.showSnackbar(`${camera.title} удален`);
        onClose();
        navigate('/place-settings/cameras');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                Подтверждение удаления
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Вы уверены, что хотите удалить камеру {camera.title}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button
                    onClick={handleSubmit}
                    variant={'contained'}
                    color={'primary'}
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(ConfirmDeleteCameraForm);
