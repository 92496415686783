import { makeAutoObservable } from 'mobx';

import AuthStore from './auth';
import GeneralStore from './general';
import PlacesStore from './places';
import VehiclesStore from './vehicles';
import CamerasStore from './cameras';
import HubsStore from './hubs';
import VideosStore from './videos';
import AnalyticsStore from './analytics';

const generalStore = new GeneralStore();
const authStore = new AuthStore();
const placesStore = new PlacesStore();
const vehiclesStore = new VehiclesStore();
const camerasStore = new CamerasStore();
const hubsStore = new HubsStore();
const videosStore = new VideosStore();
const analyticsStore = new AnalyticsStore();

interface IStores {
    generalStore;
    authStore;
    placesStore;
    vehiclesStore;
    camerasStore;
    hubsStore;
    videosStore;
    analyticsStore;
}

export class RootStore {
    generalStore: GeneralStore;
    authStore: AuthStore;
    placesStore: PlacesStore;
    vehiclesStore: VehiclesStore;
    camerasStore: CamerasStore;
    hubsStore: HubsStore;
    videosStore: VideosStore;
    analyticsStore: AnalyticsStore;

    constructor(stores: IStores) {
        makeAutoObservable(this);
        this.generalStore = stores.generalStore;
        this.authStore = stores.authStore;
        this.placesStore = stores.placesStore;
        this.vehiclesStore = stores.vehiclesStore;
        this.camerasStore = stores.camerasStore;
        this.hubsStore = stores.hubsStore;
        this.videosStore = stores.videosStore;
        this.analyticsStore = stores.analyticsStore;
    }
}

const stores = {
    generalStore,
    authStore,
    placesStore,
    vehiclesStore,
    camerasStore,
    hubsStore,
    videosStore,
    analyticsStore,
};

const rootStore = new RootStore(stores);
export default rootStore;
