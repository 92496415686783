import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from '../../../../hooks';
import moment from 'moment';
import FilterBlock from './components/FilterBlock';
import { Button, Paper, Typography } from '../../../../components/libs';
import { ChevronLeft } from '@material-ui/icons';
import PlaceSelect from '../../../../components/PlaceSelect';
import Loader from '../../../../components/Loader';
import { Grid } from '@material-ui/core';
import VideoItem from './components/VideoItem';
import { VideosForms } from '../../../../stores/videos';
import ShowVideoForm from './forms/ShowVideoForm';

const CameraVideosPage: React.FC = () => {
    const navigate = useNavigate();

    const { generalStore, camerasStore, videosStore, placesStore } =
        useStores();

    const { cameraId } = useParams<{ cameraId: string }>();
    const chosenCamera = camerasStore.getCamera(cameraId);
    const chosenVideosDate = videosStore.chosenVideosDate;
    const currentPlace = placesStore.currentPlace;

    useEffect(() => {
        generalStore.setPageTitle(
            chosenCamera
                ? `${chosenCamera.title} (${chosenCamera.externalId})`
                : '',
            [<PlaceSelect />],
        );
    }, [currentPlace?.id, chosenCamera?.id]);

    useEffect(() => {
        if (!currentPlace || !chosenCamera || !chosenVideosDate) return;
        videosStore.loadVideos({
            placeId: placesStore.currentPlace.id,
            cameraId: chosenCamera.id,
            sinceDt: moment(chosenVideosDate).startOf('day').toDate(),
            tillDt: moment(chosenVideosDate).endOf('day').toDate(),
        });
    }, [chosenCamera?.id, chosenVideosDate]);

    useEffect(() => {
        if (chosenCamera) return;
        if (cameraId != chosenCamera?.id) {
            if (cameraId) {
                camerasStore.loadCameras().then(() => {
                    camerasStore.setChosenCamera(chosenCamera);
                });
            }
        }
    }, [cameraId]);

    console.log(chosenCamera);

    return (
        <>
            <FilterBlock />
            <Button
                color={'primary'}
                onClick={() => {
                    navigate('/analytics/videos');
                }}
                style={{ marginBottom: 10, marginTop: 10 }}
            >
                <ChevronLeft fontSize={'small'} />
                <span>Назад к камерам</span>
            </Button>
            {chosenCamera && videosStore.isLoading && <Loader />}
            {chosenCamera && !videosStore.isLoading && (
                <Grid container spacing={5} xs={12} md={8} lg={6}>
                    {videosStore.videos.length == 0 && (
                        <Grid item xs={12}>
                            <Paper p={5}>
                                <Typography variant={'body1'}>
                                    Нет обработанных видео
                                </Typography>
                            </Paper>
                        </Grid>
                    )}
                    {videosStore.videos.length > 0 &&
                        videosStore.videos.map((video) => (
                            <Grid item xs={12}>
                                <VideoItem
                                    video={video}
                                    onClick={() => {
                                        videosStore.setChosenVideo(video);
                                        videosStore.openForm(
                                            VideosForms.ShowVideo,
                                        );
                                    }}
                                />
                            </Grid>
                        ))}
                </Grid>
            )}
            {videosStore.chosenVideo && chosenCamera && (
                <ShowVideoForm
                    open={videosStore.formsState.ShowVideo}
                    onClose={() => {
                        videosStore.closeForm(VideosForms.ShowVideo);
                        videosStore.setChosenVideo(null);
                    }}
                    video={videosStore.chosenVideo}
                    camera={chosenCamera}
                />
            )}
        </>
    );
};

export default observer(CameraVideosPage);
