import React from 'react';

export const Sleep: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg
            height='511pt'
            viewBox='0 0 511 511.99992'
            width='511pt'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='m486.828125 307.027344c-36.738281 33.320312-84.238281 51.671875-133.738281 51.671875-109.886719 0-199.289063-89.398438-199.289063-199.289063 0-49.5 18.351563-96.996094 51.671875-133.738281 10.304688-11.363281-.746094-29.238281-15.535156-25.046875-108.429688 30.714844-189.4375 130.582031-189.4375 250.765625 0 144.03125 116.558594 260.609375 260.609375 260.609375 119.90625 0 219.972656-80.734375 250.765625-189.4375 4.183594-14.757812-13.65625-25.859375-25.046875-15.535156zm-225.71875 174.3125c-126.792969 0-229.949219-103.15625-229.949219-229.949219 0-85.132813 47.5-162.375 120.066406-202.027344-18.316406 33.503907-28.085937 71.242188-28.085937 110.046875 0 126.792969 103.152344 229.949219 229.949219 229.949219 38.808594 0 76.542968-9.769531 110.046875-28.085937-39.652344 72.566406-116.894531 120.066406-202.027344 120.066406zm0 0' />
            <path d='m254.363281 202.359375 36.308594 18.15625 18.15625 36.308594c2.597656 5.191406 7.902344 8.472656 13.710937 8.472656 5.804688 0 11.113282-3.28125 13.710938-8.472656l18.15625-36.308594 36.308594-18.15625c5.191406-2.597656 8.472656-7.90625 8.472656-13.710937 0-5.808594-3.28125-11.117188-8.472656-13.714844l-36.308594-18.152344-18.15625-36.308594c-2.597656-5.195312-7.90625-8.476562-13.710938-8.476562-5.808593 0-11.117187 3.28125-13.710937 8.476562l-18.15625 36.308594-36.308594 18.152344c-5.195312 2.597656-8.476562 7.90625-8.476562 13.714844 0 5.804687 3.28125 11.113281 8.476562 13.710937zm54.589844-20.441406c2.96875-1.484375 5.375-3.890625 6.855469-6.855469l6.730468-13.457031 6.730469 13.457031c1.480469 2.964844 3.886719 5.371094 6.855469 6.855469l13.457031 6.726562-13.460937 6.730469c-2.964844 1.484375-5.371094 3.890625-6.851563 6.855469l-6.730469 13.457031-6.730468-13.457031c-1.480469-2.964844-3.886719-5.371094-6.855469-6.855469l-13.457031-6.726562zm0 0' />
            <path d='m414.410156 82.761719h15.328125v15.328125c0 8.46875 6.863281 15.332031 15.332031 15.332031 8.464844 0 15.328126-6.863281 15.328126-15.332031v-15.328125h15.328124c8.46875 0 15.332032-6.863281 15.332032-15.332031 0-8.464844-6.863282-15.328126-15.332032-15.328126h-15.328124v-15.332031c0-8.464843-6.863282-15.328125-15.328126-15.328125-8.46875 0-15.332031 6.863282-15.332031 15.328125v15.332031h-15.328125c-8.46875 0-15.332031 6.863282-15.332031 15.328126 0 8.46875 6.863281 15.332031 15.332031 15.332031zm0 0' />
        </svg>
    );
};
