import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CardActions, Grid, Tab, Tabs } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import {
    Button,
    Card,
    CardContent,
    changeEventHandlerDecorator,
    Paper,
    TextField,
} from '../../../../components/libs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStores } from '../../../../hooks';
import ConfirmDeleteVehicleForm from './forms/ConfirmDeleteVehicleForm';
import { VehiclesForms } from '../../../../stores/vehicles';

const VehiclePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { vehiclesStore, placesStore, generalStore } = useStores();

    const { vehicleId } = useParams<{ vehicleId: string }>();
    const currentVehicle = vehiclesStore.getVehicle(vehicleId);

    const [tabValue, setTabValue] = useState<number>(0);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [recognitionTag, setRecognitionTag] = useState<string>('');

    const updateVehicle = async () => {
        await vehiclesStore.updateVehicle(vehicleId, {
            title,
            description,
            recognitionTag,
        });
        generalStore.showSnackbar('Данные обновлены');
        vehiclesStore.loadVehicles();
    };

    useEffect(() => {
        if (location.pathname.includes('information')) {
            setTabValue(0);
        } else {
            setTabValue(0);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!placesStore.currentPlace) {
            placesStore.loadPlaces();
        }
        if (!currentVehicle && placesStore.currentPlace) {
            vehiclesStore.loadVehicles();
        }
    }, [placesStore.currentPlace]);

    useEffect(() => {
        if (currentVehicle) {
            setTitle(currentVehicle.title);
            setDescription(currentVehicle.description);
            setRecognitionTag(currentVehicle.recognitionTag);
            generalStore.setPageTitle(currentVehicle.title);
        }
    }, [currentVehicle]);

    return (
        <>
            <>
                <Button
                    color={'primary'}
                    onClick={() => navigate('/place-settings/vehicles')}
                >
                    <ChevronLeft fontSize={'small'} />
                    <span style={{ marginLeft: '3px', fontSize: 'small' }}>
                        Назад
                    </span>
                </Button>
                <Tabs value={tabValue} style={{ marginTop: '10px' }}>
                    <Tab
                        style={{ fontWeight: tabValue === 0 && 'bold' }}
                        label='Основная информация'
                        onClick={(event: React.MouseEvent) => {
                            event.preventDefault();
                            navigate(
                                `/place-settings/vehicles/${vehicleId}/information`,
                            );
                        }}
                    />
                </Tabs>
            </>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Card>
                        <Paper>
                            <CardContent>
                                <TextField
                                    value={title}
                                    id='vehicleTitle'
                                    variant='outlined'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    label='Название'
                                    type={'text'}
                                    fullWidth
                                    onChange={changeEventHandlerDecorator(
                                        setTitle,
                                    )}
                                />
                                <TextField
                                    value={description}
                                    id='vehicleDescription'
                                    variant='outlined'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    label='Описание'
                                    type={'text'}
                                    fullWidth
                                    onChange={changeEventHandlerDecorator(
                                        setDescription,
                                    )}
                                />
                                <TextField
                                    value={recognitionTag}
                                    id='vehicleRecognitionTag'
                                    variant='outlined'
                                    color='primary'
                                    style={{ marginTop: 10 }}
                                    label='Класс для распознавания'
                                    type={'text'}
                                    fullWidth
                                    onChange={changeEventHandlerDecorator(
                                        setRecognitionTag,
                                    )}
                                />
                            </CardContent>
                        </Paper>
                        <CardActions
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                color={'default'}
                                variant={'contained'}
                                onClick={() => {
                                    vehiclesStore.openForm(
                                        VehiclesForms.DeleteForm,
                                    );
                                }}
                            >
                                Удалить
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={updateVehicle}
                                disabled={!title || !recognitionTag}
                            >
                                Сохранить
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            {currentVehicle && (
                <ConfirmDeleteVehicleForm
                    open={vehiclesStore.formsState.DeleteForm}
                    vehicle={currentVehicle}
                    onClose={() =>
                        vehiclesStore.closeForm(VehiclesForms.DeleteForm)
                    }
                />
            )}
        </>
    );
};

export default observer(VehiclePage);
