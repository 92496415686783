import React from 'react';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import { RouteInfoType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import ReportsPage from './pages/Reports/ReportsPage';
import VideosPage from './pages/Videos/VideosPage';
import CameraVideosPage from './pages/Videos/CameraVideosPage';

const adminRoutes = {
    id: 'admin',
    path: '/analytics',
    name: 'fd',
    icon: null,
    hide: true,
    component: <LayoutWithMenu />,
    routes: [
        {
            index: true,
            path: '',
            redirect: '/analytics/reports',
            // exact: true,
            hide: true,
        },
        {
            path: 'analytics',
            header: 'Аналитика',
            type: 'section',
        },
        {
            path: '/analytics/reports',
            name: 'Отчёты',
            type: 'route',
            component: <ReportsPage />,
            icon: <SidebarIcon type={SidebarIconType.measurements} />,
        },
        {
            path: '/analytics/videos/:cameraId',
            name: 'Видео',
            type: 'route',
            component: <CameraVideosPage />,
            icon: <SidebarIcon type={SidebarIconType.pulse} />,
            hide: true,
        },
        {
            path: '/analytics/videos',
            name: 'Видео',
            type: 'route',
            component: <VideosPage />,
            icon: <SidebarIcon type={SidebarIconType.pulse} />,
            // hide: false
        },
    ],
} as RouteInfoType;

export default adminRoutes;
