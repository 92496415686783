import { MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStores } from '../../hooks';
import StyledSelect from '../Select';

interface IProps {
    placeId?: string;
    redirect?: boolean;
}

const PlaceSelect = (props: IProps) => {
    const { placesStore, authStore } = useStores();

    useEffect(() => {
        if (!authStore.isAuthorized) return;
        placesStore.loadPlaces().then(() => {
            if (placesStore.places.length > 0) {
                const placeId = JSON.parse(localStorage.getItem('placeId'));

                if (placeId) {
                    placesStore.setCurrentPlace(placeId);
                } else {
                    const currentPlaceId = placesStore.places[0].id;
                    placesStore.setCurrentPlace(currentPlaceId);
                    localStorage.setItem(
                        'placeId',
                        JSON.stringify(currentPlaceId),
                    );
                }
            }
        });
    }, [placesStore.places.length, authStore.isAuthorized]);

    const handleChange = (e) => {
        const { value } = e.target;
        placesStore.setCurrentPlace(value);
        localStorage.setItem('placeId', JSON.stringify(value));
    };

    return (
        <form autoComplete='off'>
            <StyledSelect
                my={0}
                isDefaultInput={false}
                value={
                    props.placeId ||
                    (placesStore.currentPlace?.id || 0).toString()
                }
                options={placesStore.places.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.data.title}
                    </MenuItem>
                ))}
                style={{ minWidth: 150 }}
                handleChange={handleChange}
                selectName={'placeId'}
                label={'Площадка'}
            />
        </form>
    );
};

export default observer(PlaceSelect);
