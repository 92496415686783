import React, { Suspense } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from '../types/types';
import { CssBaseline } from '@material-ui/core';

import { SnackbarComponent } from '../components/Snackbars';
import { Outlet } from 'react-router-dom';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
    max-width: 520px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
`;

const AuthLayout: React.FC = () => {
    return (
        <Root>
            <SnackbarComponent />
            <CssBaseline />
            <GlobalStyle />
            <Suspense fallback={<>...</>}>
                <Outlet />
            </Suspense>
        </Root>
    );
};

export default AuthLayout;
