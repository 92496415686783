import React from 'react';
import { observer } from 'mobx-react';
import { Switch, TextField, Typography } from '@material-ui/core';

export interface PlaceModuleProps {
    moduleTitle: string;
    moduleDescription: string;
    moduleState: boolean;
    moduleHandleChange: () => void;
    afterSubModuleLabel?: string;
    afterSubModuleValue?: number;
    afterSubModuleHandleChange?: (e) => void;
    untilSubModuleLabel?: string;
    untilSubModuleValue?: number;
    untilSubModuleHandleChange?: (e) => void;
}

const PlaceModule = (props: PlaceModuleProps) => {
    return (
        <div
            style={{
                backgroundColor: '#F6F6F6',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 15,
                marginTop: 10,
                borderRadius: 5,
            }}
        >
            <div>
                <Typography variant={'h6'} style={{ fontWeight: 'bold' }}>
                    {props.moduleTitle}
                </Typography>
                <Typography variant={'body2'} style={{ color: '#6B6B6B' }}>
                    {props.moduleDescription}
                </Typography>
                {props.afterSubModuleLabel &&
                props.untilSubModuleLabel &&
                props.moduleState ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            value={props.afterSubModuleValue}
                            id='afterSubModule'
                            variant='outlined'
                            style={{ marginTop: 10, width: 180 }}
                            label={props.afterSubModuleLabel}
                            type={'number'}
                            InputProps={{
                                inputProps: {
                                    max: props.untilSubModuleValue - 1,
                                    min: 1,
                                },
                            }}
                            fullWidth
                            onChange={props.afterSubModuleHandleChange}
                        />
                        <TextField
                            value={props.untilSubModuleValue}
                            id='untilSumModule'
                            variant='outlined'
                            style={{
                                marginTop: 10,
                                width: 180,
                                marginLeft: 20,
                            }}
                            label={props.untilSubModuleLabel}
                            type={'number'}
                            InputProps={{
                                inputProps: {
                                    max: 30,
                                    min: 1,
                                },
                            }}
                            fullWidth
                            onChange={props.untilSubModuleHandleChange}
                        />
                    </div>
                ) : null}
            </div>
            <Switch
                defaultChecked
                checked={props.moduleState}
                onChange={props.moduleHandleChange}
            />
        </div>
    );
};

export default observer(PlaceModule);
