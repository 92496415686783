import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import {
    Button,
    changeEventHandlerDecorator,
    TextField,
} from '../../../../../components/libs';
import { useStores } from '../../../../../hooks';

interface CreateVehicleFormProps {
    open: boolean;
    onClose: () => void;
}

const CreateVehicleForm: React.FC<CreateVehicleFormProps> = ({
    open,
    onClose,
}) => {
    const { vehiclesStore, placesStore } = useStores();

    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [recognitionTag, setRecognitionTag] = useState<string>('');

    const handleSubmit = async () => {
        await vehiclesStore.addVehicle({
            title: title,
            description: description,
            recognitionTag: recognitionTag,
            placeId: placesStore.currentPlace.id,
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id='form-dialog-title'>Добавление техники</DialogTitle>
            <DialogContent>
                <form autoComplete='off'>
                    <TextField
                        value={title}
                        id='vehicleTitle'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Название'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setTitle)}
                    />
                    <TextField
                        value={description}
                        id='vehicleDescription'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Описание'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setDescription)}
                    />
                    <TextField
                        value={recognitionTag}
                        id='vehicleRecognitionTag'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Класс для распознавания'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(
                            setRecognitionTag,
                        )}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='default' variant='contained' onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!title || !recognitionTag}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(CreateVehicleForm);
