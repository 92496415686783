import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { PlacesForms } from '../../../stores/places';
import {
    Button,
    changeEventHandlerDecorator,
    TextField,
    Typography,
} from '../../../components/libs';
import PlaceModule from '../pages/components/PlaceModule';
import {
    PlaceAvailableModules,
    PlaceData,
} from '../../../services/places/models';

interface PlaceFormProps {
    open: boolean;
    onClose: (type: 'EditForm' | 'CreateForm') => void;
}

const PlaceForm: React.FC<PlaceFormProps> = ({ open, onClose }) => {
    const { placesStore } = useStores();
    const type = placesStore.formsState.CreateForm
        ? PlacesForms.CreateForm
        : placesStore.formsState.EditForm
        ? PlacesForms.EditForm
        : undefined;

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [vehiclesMonitoring, setVehiclesMonitoring] = useState(false);
    const [garbageCollectionMonitoring, setGarbageCollectionMonitoring] =
        useState(false);

    const handleClose = () => {
        onClose(type);
    };

    const handleSubmit = async () => {
        const data: PlaceData = {
            title,
            description,
        };
        const availableModules: PlaceAvailableModules = {
            vehiclesMonitoring,
            garbageCollectionMonitoring,
        };
        switch (type) {
            case PlacesForms.CreateForm:
                await placesStore.createPlace(data, availableModules);
                handleClose();
                break;
            case PlacesForms.EditForm:
                await placesStore.updatePlace(
                    placesStore.placeToEdit.id,
                    data,
                    availableModules,
                );
                handleClose();
                break;
        }
    };

    useEffect(() => {
        const place = placesStore.placeToEdit;
        setTitle(place?.data?.title || '');
        setDescription(place?.data?.description || '');
        setVehiclesMonitoring(
            place?.availableModules?.vehiclesMonitoring || false,
        );
        setGarbageCollectionMonitoring(
            place?.availableModules?.garbageCollectionMonitoring || false,
        );
    }, [placesStore.placeToEdit, open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id='form-dialog-title'>
                {type == PlacesForms.CreateForm
                    ? 'Добавление площадки'
                    : type == PlacesForms.EditForm
                    ? 'Редактирование площадки'
                    : ''}
            </DialogTitle>
            <DialogContent>
                <form autoComplete='off'>
                    <TextField
                        value={title}
                        id='placeTitle'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Название площадки'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setTitle)}
                    />
                    <TextField
                        value={description}
                        id='placeDescription'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Описание'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setDescription)}
                    />
                    <Typography variant={'h6'} style={{ marginTop: 30 }}>
                        Настройка модулей
                    </Typography>
                    <div style={{ marginTop: 10 }}>
                        <PlaceModule
                            moduleTitle={'Мониторинг техники'}
                            moduleDescription={
                                'Автоматический подсчет времени работы техники'
                            }
                            moduleState={vehiclesMonitoring}
                            moduleHandleChange={() =>
                                setVehiclesMonitoring(!vehiclesMonitoring)
                            }
                        />
                        <PlaceModule
                            moduleTitle={'Мониторинг вывоза мусора'}
                            moduleDescription={
                                'Автоматический подсчет количества вывоза мусора с площадки'
                            }
                            moduleState={garbageCollectionMonitoring}
                            moduleHandleChange={() =>
                                setGarbageCollectionMonitoring(
                                    !garbageCollectionMonitoring,
                                )
                            }
                        />
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color='default'
                    variant='contained'
                    onClick={handleClose}
                >
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!title}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(PlaceForm);
