export enum ExecutionStatus {
    finished = 'finished',
    failed = 'failed',
}

export interface GeneralResponse<T> {
    status: ExecutionStatus;
    message: string;
    result: T;
}
