import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStores } from '../../../../../hooks';
import { Button } from '../../../../../components/libs';
import { ChevronLeft } from '@material-ui/icons';
import { Tab, Tabs } from '@material-ui/core';
import { CamerasForm } from '../../../../../stores/cameras';
import ConfirmDeleteCameraForm from '../forms/ConfirmDeleteCameraForm';
import { EditCameraInfo } from './components/EditCameraInfo';

const CameraPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { camerasStore, placesStore, generalStore } = useStores();

    const { cameraId } = useParams<{ cameraId: string }>();
    const currentCamera = camerasStore.getCamera(cameraId);

    const [tabValue, setTabValue] = useState<string>('information');

    const isCurrentTab = (value: string) => {
        return tabValue === value;
    };

    useEffect(() => {
        if (location.pathname.includes('information')) {
            setTabValue('information');
        } else {
            setTabValue('information');
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!placesStore.currentPlace) {
            placesStore.loadPlaces();
        }
        if (!currentCamera && placesStore.currentPlace) {
            camerasStore.loadCameras();
        }
    }, [placesStore.currentPlace]);

    useEffect(() => {
        if (currentCamera) {
            generalStore.setPageTitle(`Камера: ${currentCamera.title}`);
        }
    }, [currentCamera]);

    return (
        <>
            <>
                <Button
                    color={'primary'}
                    onClick={() => navigate('/place-settings/cameras')}
                >
                    <ChevronLeft fontSize={'small'} />
                    <span style={{ marginLeft: '3px', fontSize: 'small' }}>
                        Назад
                    </span>
                </Button>
                <Tabs value={tabValue} style={{ marginTop: '10px' }}>
                    <Tab
                        style={{
                            fontWeight: isCurrentTab('information') && 'bold',
                        }}
                        label='Основная информация'
                        onClick={(event: React.MouseEvent) => {
                            event.preventDefault();
                            navigate(
                                `/place-settings/cameras/${cameraId}/information`,
                            );
                        }}
                    />
                </Tabs>
            </>
            {isCurrentTab('information') && (
                <EditCameraInfo camera={currentCamera} />
            )}
            {currentCamera && (
                <ConfirmDeleteCameraForm
                    open={camerasStore.formsState.DeleteForm}
                    camera={currentCamera}
                    onClose={() =>
                        camerasStore.closeForm(CamerasForm.DeleteForm)
                    }
                />
            )}
        </>
    );
};

export default observer(CameraPage);
