import React, { useEffect, useState } from 'react';
import { HubsForms } from '../../../../stores/hubs';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
} from '@material-ui/core';
import {
    Button,
    changeEventHandlerDecorator,
    Chip,
    TextField,
    Typography,
} from '../../../../components/libs';

interface EditHubFormProps {
    open: boolean;
    onClose: (type: HubsForms.CreateForm | HubsForms.EditForm) => void;
}

const HubForm: React.FC<EditHubFormProps> = ({ open, onClose }) => {
    const { hubsStore, placesStore } = useStores();
    const type = hubsStore.formsState.CreateForm
        ? HubsForms.CreateForm
        : hubsStore.formsState.EditForm
        ? HubsForms.EditForm
        : undefined;

    const [title, setTitle] = useState('');
    const [httpApiHost, setHttpApiHost] = useState('');
    const [httpApiPort, setHttpApiPort] = useState(0);
    const [httpApiProtocol, setHttpApiProtocol] = useState('');
    const [token, setToken] = useState('');
    const [uuid, setUuid] = useState('');
    const [placeIds, setPlaceIds] = useState([]);

    const handleClose = () => {
        onClose(type);
    };

    const handlePlaceIdsChange = (e) => {
        const { value } = e.target;
        setPlaceIds(value);
    };

    const handleSubmit = async () => {
        switch (type) {
            case HubsForms.CreateForm:
                await hubsStore.addHub({
                    title,
                    httpApiHost,
                    httpApiPort,
                    httpApiProtocol,
                    token,
                    uuid,
                    placeIds,
                });
                handleClose();
                break;
            case HubsForms.EditForm:
                await hubsStore.updateHub(hubsStore.hubToEdit.id, {
                    title,
                    httpApiHost,
                    httpApiPort,
                    httpApiProtocol,
                    token,
                    uuid,
                    placeIds,
                });
                handleClose();
                break;
        }
    };

    useEffect(() => {
        const hub = hubsStore.hubToEdit;
        setTitle(hub?.title || '');
        setHttpApiHost(hub?.httpApiHost || '');
        setHttpApiPort(hub?.httpApiPort || 0);
        setHttpApiProtocol(hub?.httpApiProtocol || 'https');
        setToken(hub?.token || '');
        setUuid(hub?.uuid || '');
        setPlaceIds(hub?.placeIds || []);
    }, [hubsStore.hubToEdit, open]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id='form-dialog-title'>
                {type == HubsForms.CreateForm
                    ? 'Добавление хаба'
                    : type == HubsForms.EditForm
                    ? 'Редактирование хаба'
                    : ''}
            </DialogTitle>
            <DialogContent>
                <form autoComplete='off'>
                    <TextField
                        value={title}
                        id='hubTitle'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Название'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setTitle)}
                    />
                    <TextField
                        value={uuid}
                        id='hubUuid'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='UUID Хаба'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setUuid)}
                    />
                    <TextField
                        value={token}
                        id='hubToken'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Токен авторизации'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setToken)}
                    />
                    <Typography variant={'h6'} style={{ marginTop: 35 }}>
                        Настройки HTTP
                    </Typography>
                    <TextField
                        value={httpApiProtocol}
                        id='hubHttpApiProtocol'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='HTTP Протокол'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(
                            setHttpApiProtocol,
                        )}
                    />
                    <TextField
                        value={httpApiHost}
                        id='hubHttpApiHost'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='HTTP Хост'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setHttpApiHost)}
                    />
                    <TextField
                        value={httpApiPort}
                        id='hubHttpApiPort'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='HTTP Порт'
                        type={'number'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setHttpApiPort)}
                    />
                    <Typography variant={'h6'} style={{ marginTop: 35 }}>
                        Площадки
                    </Typography>
                    <Select
                        style={{ minWidth: '300px', marginTop: 10 }}
                        value={placeIds}
                        id='places'
                        variant={'outlined'}
                        multiple
                        input={
                            <OutlinedInput
                                id='select-multiple-chip'
                                label='Выберите площадки'
                            />
                        }
                        onChange={handlePlaceIdsChange}
                        renderValue={(selected: number[]) => (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 5,
                                }}
                            >
                                {selected.map((id) => (
                                    <Chip
                                        key={id}
                                        variant={'default'}
                                        color={'secondary'}
                                        label={Object.values(
                                            placesStore.places,
                                        ).map((value) => value.data.title)}
                                    />
                                ))}
                            </Box>
                        )}
                    >
                        {Object.values(placesStore.places).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox
                                    checked={placeIds.includes(item.id)}
                                />
                                <ListItemText primary={item.data.title} />
                            </MenuItem>
                        ))}
                    </Select>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color='default'
                    variant='contained'
                    onClick={handleClose}
                >
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!title}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(HubForm);
