import React from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import { Video } from '../../../../../services/videos/models';
import { Paper, Typography } from 'components/libs';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';
import { Vehicle as VehicleIcon } from '../../../../../icons/Vehicle';
import { WorkTime as WorkTimeIcon } from '../../../../../icons/WorkTime';
import { Sleep as SleepIcon } from '../../../../../icons/Sleep';
import VideoIconInfo from './VideoIconInfo';
import { getVideoFileName, getWorkTimeStr } from '../../../../../utils';

export interface VideoItemProps {
    video: Video;
    onClick: () => void;
}

const VideoItem: React.FC<VideoItemProps> = ({ video, onClick }) => {
    const totalWorkSeconds = video.monitoringData.reduce((acc, item) => {
        return acc + item.work;
    }, 0);

    const totalIdleSeconds = video.monitoringData.reduce((acc, item) => {
        return acc + item.idle;
    }, 0);

    const inCounter = video.entriesHistory.filter(
        (item) => item.direction == 'in',
    ).length;

    const outCounter = video.entriesHistory.filter(
        (item) => item.direction == 'out',
    ).length;

    return (
        <Paper p={5} onClick={onClick}>
            <Grid container spacing={5}>
                <Grid item xs={10}>
                    <Typography style={{ fontWeight: 'bold' }} variant='h4'>
                        {getVideoFileName(video)}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{ color: '#7D7D7D' }}>
                        {moment(video.receivedAt)
                            .add(3, 'hours')
                            .format('DD.MM.YY HH:mm')}
                    </Typography>
                </Grid>
                <Grid container item xs={12} spacing={5}>
                    <Grid item xs={12} md={4}>
                        <VideoIconInfo
                            title={video.monitoringData.length.toString()}
                            description={'Кол-во'}
                            icon={
                                <VehicleIcon
                                    color={'#2a73ff'}
                                    fill={'#2a73ff'}
                                    width={25}
                                    height={25}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <VideoIconInfo
                            title={getWorkTimeStr(totalWorkSeconds)}
                            description={'Работа'}
                            icon={
                                <WorkTimeIcon
                                    stroke={'#2a73ff'}
                                    width={25}
                                    height={25}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <VideoIconInfo
                            title={getWorkTimeStr(totalIdleSeconds)}
                            description={'Простой'}
                            icon={
                                <SleepIcon
                                    color={'#2a73ff'}
                                    fill={'#2a73ff'}
                                    width={25}
                                    height={25}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
            {inCounter > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Въехало: {inCounter}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {outCounter > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Выехало: {outCounter}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {video.monitoringData.length > 0 && (
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>Техника:</Typography>
                    </Grid>
                    {video.monitoringData.map((item, index) => (
                        <Grid item xs={12}>
                            <Typography>
                                {index + 1}. {item.vehicle.title} (Работа:{' '}
                                {getWorkTimeStr(item.work)}, Простой:{' '}
                                {getWorkTimeStr(item.idle)})
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Paper>
    );
};

export default VideoItem;
