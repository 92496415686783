import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Paper, Typography } from '../../../../../components/libs';
import {
    EnhancedTableHead,
    HeadCell,
} from '../../../../../components/TableHelpers';
import { getWorkTimeStr } from '../../../../../utils';

const tableHeadCells: Array<HeadCell> = [
    { id: 'number', alignment: 'left', label: '#' },
    { id: 'vehicleName', alignment: 'left', label: 'Техника' },
    { id: 'work', alignment: 'left', label: 'Работа' },
    { id: 'idle', alignment: 'left', label: 'Простой' },
];

const Reports: React.FC = () => {
    const { analyticsStore } = useStores();

    const vehiclesMonitoringData = analyticsStore.vehiclesMonitoringData;
    const totalWorkSeconds = vehiclesMonitoringData.reduce((acc, item) => {
        return acc + item.work;
    }, 0);
    const totalIdleSeconds = vehiclesMonitoringData.reduce((acc, item) => {
        return acc + item.idle;
    }, 0);

    console.log(vehiclesMonitoringData.length);

    return (
        <Grid container>
            <Grid item xs={12} md={8} lg={6}>
                {vehiclesMonitoringData.length == 0 && (
                    <Paper p={5}>
                        <Typography>Нет данных</Typography>
                    </Paper>
                )}
                {vehiclesMonitoringData.length > 0 && (
                    <Paper p={5}>
                        <Typography variant={'h4'}>
                            Время работы техники
                        </Typography>
                        <Table
                            aria-labelledby='tableTitle'
                            size={'medium'}
                            aria-label='enhanced table'
                            style={{ marginTop: 10 }}
                        >
                            <EnhancedTableHead cells={tableHeadCells} />
                            <TableBody>
                                {vehiclesMonitoringData.map((item, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`${index}`}
                                    >
                                        <TableCell align='left'>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {item.vehicle.title}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {getWorkTimeStr(item.work)}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {getWorkTimeStr(item.idle)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow hover tabIndex={-1} key={`total`}>
                                    <TableCell align='left'></TableCell>
                                    <TableCell align='left'>
                                        <Typography style={{ fontWeight: 700 }}>
                                            Итого:
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='left'>
                                        <Typography style={{ fontWeight: 700 }}>
                                            {getWorkTimeStr(totalWorkSeconds)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='left'>
                                        <Typography style={{ fontWeight: 700 }}>
                                            {getWorkTimeStr(totalIdleSeconds)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                )}
            </Grid>
        </Grid>
    );
};

export default observer(Reports);
