import React from 'react';
import { Vehicle } from '../../../../../services/vehicles/models';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Button } from '../../../../../components/libs';

interface ConfirmDeleteVehicleFormProps {
    open: boolean;
    onClose: () => void;
    vehicle: Vehicle;
}

const ConfirmDeleteVehicleForm: React.FC<ConfirmDeleteVehicleFormProps> = ({
    open,
    onClose,
    vehicle,
}) => {
    const { generalStore, vehiclesStore } = useStores();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        await vehiclesStore.deleteVehicle(vehicle.id);
        generalStore.showSnackbar(`${vehicle.title} удален`);
        onClose();
        navigate('/place-settings/vehicles');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                Подтверждение удаления
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Вы уверены, что хотите удалить {vehicle.title}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button
                    onClick={handleSubmit}
                    variant={'contained'}
                    color={'primary'}
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(ConfirmDeleteVehicleForm);
