import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useStores } from '../../../../../hooks';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import {
    Button,
    changeEventHandlerDecorator,
    TextField,
} from '../../../../../components/libs';

interface CreateCameraFormProps {
    open: boolean;
    onClose: () => void;
}

const CreateCameraForm: React.FC<CreateCameraFormProps> = ({
    open,
    onClose,
}) => {
    const { camerasStore, placesStore } = useStores();

    const [title, setTitle] = useState<string>('');
    const [externalId, setExternalId] = useState<string>('');
    const [videosSource, setVideosSource] = useState<string>('');

    const handleSubmit = async () => {
        await camerasStore.addCamera({
            title: title,
            externalId: externalId,
            placeId: placesStore.currentPlace.id,
            videosSource: videosSource,
        });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id='form-dialog-title'>Добавление камеры</DialogTitle>
            <DialogContent>
                <form autoComplete='off'>
                    <TextField
                        value={title}
                        id='cameraTitle'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Название'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setTitle)}
                    />
                    <TextField
                        value={externalId}
                        id='cameraExternalId'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Внешний идентификатор'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setExternalId)}
                    />
                    <TextField
                        value={videosSource}
                        id='cameraVideosSource'
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 10 }}
                        label='Адрес обработанных видео'
                        type={'text'}
                        fullWidth
                        onChange={changeEventHandlerDecorator(setVideosSource)}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='default' variant='contained' onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={!title}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(CreateCameraForm);
