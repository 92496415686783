import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RouterConfig from './router';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
    <RouterConfig />,
    document.getElementById('root') as HTMLElement,
);
