import React, { createContext, FC, ReactNode } from 'react';
import { RootStore } from '../stores';

export const StoreContext = createContext<RootStore>({} as RootStore);

export interface StoreComponent {
    store: RootStore;
    children?: ReactNode;
}

export const StoreProvider: FC<StoreComponent> = ({ children, store }) => {
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};
