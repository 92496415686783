import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { Vehicle } from '../services/vehicles/models';
import { request } from '../apiCommandsExecutor';
import {
    CreateVehicleRequest,
    GetVehiclesResponse,
    UpdateVehicleRequest,
} from '../services/vehicles/requestResponses';
import rootStore from './index';

export enum VehiclesForms {
    CreateForm = 'CreateForm',
    DeleteForm = 'DeleteForm',
}

class VehiclesStore {
    formsState: { [key in VehiclesForms]: boolean } = {
        CreateForm: false,
        DeleteForm: false,
    };

    isLoading: boolean = false;
    vehicles: Vehicle[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    async loadVehicles() {
        this.setLoading(true);
        const result = await request<GetVehiclesResponse>('/vehicles', {
            params: {
                placeId: rootStore.placesStore.currentPlace?.id,
            },
        });

        if (result) {
            runInAction(() => {
                this.vehicles = result.vehicles;
            });
        }
        this.setLoading(false);
    }

    @computed
    getVehicle(vehicleId: string) {
        return this.vehicles.find((vehicle) => vehicle.id == vehicleId);
    }

    async addVehicle(params: CreateVehicleRequest) {
        const result = await request<Vehicle>('/vehicles', {
            method: 'POST',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadVehicles();
        }
    }

    async updateVehicle(vehicleId: string, params: UpdateVehicleRequest) {
        const result = await request<Vehicle>(`/vehicles/${vehicleId}`, {
            method: 'PUT',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadVehicles();
        }
    }

    async deleteVehicle(vehicleId: string) {
        const result = await request<boolean>(`/vehicles/${vehicleId}`, {
            method: 'DELETE',
        });

        if (result) {
            this.loadVehicles();
        }
    }

    @action
    openForm(type: keyof typeof VehiclesForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof VehiclesForms) {
        this.formsState[type] = false;
    }
}

export default VehiclesStore;
