import { makeAutoObservable, runInAction } from 'mobx';
import { removeTokenPair, saveTokenPair } from '../apiCommandsExecutor/token';
import { request } from '../apiCommandsExecutor';
import {
    SignInRequest,
    SignUpRequest,
} from '../services/users/requestResponses';
import { User, TokenPair } from '../services/users/models';
import { Organization } from '../services/organizations/models';

class AuthStore {
    isAuthorized: boolean;
    addUserAuthStatus: boolean;
    currentUser?: User;
    currentOrganization?: Organization;

    constructor() {
        makeAutoObservable(this);
    }

    async getCurrentOrganization() {
        if (!this.currentUser) return;
        const organization = await request<Organization>(
            `/organizations/${this.currentUser.organizationId}`,
        );

        if (!organization) return;

        runInAction(() => {
            this.currentOrganization = organization;
        });
    }

    async loadUserData() {
        await this.getCurrentUser();
        await this.getCurrentOrganization();
    }

    async getCurrentUser() {
        const user = await request<User>('/users/get_me');

        if (!user) {
            await this.logout();
        } else {
            runInAction(() => {
                this.isAuthorized = true;
                this.currentUser = user;
            });
        }
    }

    //
    async signIn(params: SignInRequest) {
        const response = await request<TokenPair>('/users/sign_in', {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            runInAction(() => {
                this.isAuthorized = false;
            });
            return;
        }
        saveTokenPair(response);
        await this.getCurrentUser();
        await this.getCurrentOrganization();
    }

    async signUp(params: SignUpRequest) {
        const response = await request<TokenPair>('/users/sign_up', {
            method: 'POST',
            data: {
                ...params,
            },
        });
        if (!response) {
            runInAction(() => {
                this.isAuthorized = false;
            });
            return;
        }

        saveTokenPair(response);
        await this.getCurrentUser();
        await this.getCurrentOrganization();
    }

    //
    async logout() {
        removeTokenPair();
        runInAction(() => {
            this.isAuthorized = false;
            this.currentUser = undefined;
            this.currentOrganization = undefined;
        });
    }
}

export default AuthStore;
