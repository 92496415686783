import { RouteInfoType } from '../../types/types';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import React from 'react';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import VehiclesPage from './pages/Vehicles/VehiclesPage';
import CamerasPage from './pages/Cameras/CamerasPage';
import VehiclePage from './pages/Vehicles/VehiclePage';
import CameraPage from './pages/Cameras/CameraPage/CameraPage';

const placeSettingsRoutes = {
    id: 'place-settings',
    path: '/place-settings',
    name: 'place-settings',
    icon: null,
    hide: true,
    component: <LayoutWithMenu />,
    routes: [
        {
            index: true,
            path: '',
            redirect: '/place-settings/vehicles',
            // exact: true,
            hide: true,
        },
        {
            path: 'place-settings',
            header: 'Настройки площадки',
            type: 'section',
        },
        {
            path: '/place-settings/vehicles',
            name: 'Техника',
            type: 'route',
            component: <VehiclesPage />,
            icon: <SidebarIcon type={SidebarIconType.vehicle} />,
            // hide: false
        },
        {
            path: '/place-settings/vehicles/:vehicleId/information',
            name: 'Редактирование единицы техники',
            hide: true,
            type: 'route',
            component: <VehiclePage />,
        },
        {
            path: '/place-settings/cameras',
            name: 'Камеры',
            type: 'route',
            component: <CamerasPage />,
            icon: <SidebarIcon type={SidebarIconType.video} />,
        },
        {
            path: '/place-settings/cameras/:cameraId/information',
            name: 'Редактирование камеры',
            type: 'route',
            hide: true,
            component: <CameraPage />,
            icon: <SidebarIcon type={SidebarIconType.video} />,
        },
    ],
} as RouteInfoType;

export default placeSettingsRoutes;
