import { Video } from 'services/videos/models';
import { action, makeAutoObservable, runInAction } from 'mobx';
import {
    GetVideosRequest,
    GetVideosResponse,
} from '../services/videos/requestResponses';
import { request } from '../apiCommandsExecutor';

export enum VideosForms {
    ShowVideo = 'ShowVideo',
}

class VideosStore {
    formsState: { [key in VideosForms]: boolean } = {
        ShowVideo: false,
    };
    videos: Video[] = [];
    isLoading: boolean = false;
    chosenVideosDate: Date | null = null;
    chosenVideo: Video | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setIsLoading(v: boolean) {
        this.isLoading = v;
    }

    @action
    setVideosChosenDate(dt: Date | null) {
        this.chosenVideosDate = dt;
    }

    async loadVideos(params: GetVideosRequest) {
        this.setIsLoading(true);
        const result = await request<GetVideosResponse>('/videos', {
            params: params,
        });

        if (result) {
            runInAction(() => {
                this.videos = result.videos;
            });
        }

        this.setIsLoading(false);
    }

    @action
    setChosenVideo(video: Video | null) {
        this.chosenVideo = video;
    }

    @action
    openForm(type: keyof typeof VideosForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof VideosForms) {
        this.formsState[type] = false;
    }
}

export default VideosStore;
