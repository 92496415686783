import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStores } from '../../../../hooks';
import PlaceSelect from '../../../../components/PlaceSelect';
import { FadeInOutVertical } from '../../../../components/Animated';
import FilterBlock from './components/FilterBlock';
import { Box } from '@material-ui/core';
import Loader from '../../../../components/Loader';
import ExtendedCamerasList from './components/ExtendedCamerasList';

const VideosPage: React.FC = () => {
    const { generalStore, camerasStore } = useStores();

    useEffect(() => {
        generalStore.setPageTitle('Видео', [<PlaceSelect />]);
    }, []);
    return (
        <>
            <FadeInOutVertical show={true}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridGap: 20,
                    }}
                >
                    <FilterBlock />
                    {camerasStore.isLoading && <Loader />}
                    {!camerasStore.isLoading && <ExtendedCamerasList />}
                </Box>
            </FadeInOutVertical>
        </>
    );
};

export default observer(VideosPage);
