import React, { useEffect, useState } from 'react';
import { FormControl, Paper } from '../../../../../components/libs';
import { Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useStores } from '../../../../../hooks';
// import StyledSelect from '../../../../../components/Select';
import { observer } from 'mobx-react';
import moment from 'moment';

const FilterBlock: React.FC = () => {
    const { camerasStore, placesStore, videosStore } = useStores();

    const chosenVideosDate = videosStore.chosenVideosDate;

    const [chosenDate, setChosenDate] = useState<Date>(
        chosenVideosDate || new Date(2024, 0, 22),
    );

    useEffect(() => {
        if (!placesStore.currentPlace) return;
        camerasStore.loadCameras();
    }, [placesStore.currentPlace?.id]);

    useEffect(() => {
        if (chosenDate && placesStore.currentPlace) {
            videosStore.setVideosChosenDate(chosenDate);
            camerasStore.loadExtendedCameras({
                placeId: placesStore.currentPlace.id,
                sinceDt: moment(chosenDate).startOf('day').toDate(),
                tillDt: moment(chosenDate).endOf('day').toDate(),
            });
        }
    }, [chosenDate, placesStore.currentPlace?.id]);

    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                }}
            >
                <FormControl m={4}>
                    <DatePicker
                        inputVariant={'outlined'}
                        format={'dd.MM.yyyy'}
                        label='День'
                        value={chosenDate}
                        onChange={(date: Date) => {
                            setChosenDate(date);
                        }}
                    />
                </FormControl>
            </Box>
        </Paper>
    );
};

export default observer(FilterBlock);
