import {
    Button,
    Grid,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import React from 'react';

interface PaperHeaderProps {
    title: string;
    buttonText: string;
    onButtonClick: (() => void) | (() => Promise<void>);
    icon: any;
}

export const PaperHeader: React.FC<PaperHeaderProps> = ({
    title,
    buttonText,
    onButtonClick,
    icon,
}) => {
    return (
        <div style={{ padding: '0 10px', margin: '20px 0' }}>
            <Grid justify='space-between' container spacing={10}>
                <Grid item>
                    <Typography variant='h4' gutterBottom display='inline'>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onButtonClick}
                    >
                        {icon}
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export type HeadCell = {
    id: string;
    alignment: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
    label: string;
    disablePadding?: boolean;
    width?: number | string;
};
type EnhancedTableHeadPropsType = {
    cells: HeadCell[];
};

export const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = ({
    cells,
}) => {
    return (
        <TableHead>
            <TableRow>
                {cells.map((headCell: HeadCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignment}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        width={headCell.width}
                        size='small'
                        style={{ fontSize: 13 }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
