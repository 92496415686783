import { RouteInfoType } from '../../types/types';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import React from 'react';
import PlacesSettings from './pages/Places';
import HubsSettings from './pages/Hubs';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';

const settingsRoutes = {
    id: 'settings',
    path: '/settings',
    name: 'settings',
    icon: null,
    hide: true,
    component: <LayoutWithMenu />,
    routes: [
        {
            index: true,
            path: '',
            redirect: '/settings/places',
            // exact: true,
            hide: true,
        },
        {
            path: 'settings',
            header: 'Общие настройки',
            type: 'section',
        },
        {
            path: '/settings/places',
            name: 'Площадки',
            type: 'route',
            component: <PlacesSettings />,
            icon: <SidebarIcon type={SidebarIconType.houseBuildings} />,
            // hide: false
        },
        {
            path: '/settings/hubs',
            name: 'Настройки хабов',
            type: 'route',
            component: <HubsSettings />,
            icon: <SidebarIcon type={SidebarIconType.hubSettings} />,
        },
    ],
} as RouteInfoType;

export default settingsRoutes;
