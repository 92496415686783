import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { Hub } from '../services/hubs/models';
import { request } from '../apiCommandsExecutor';
import {
    CreateHubRequest,
    GetHubsResponse,
    UpdateHubRequest,
} from '../services/hubs/requestResponses';

export enum HubsForms {
    CreateForm = 'CreateForm',
    EditForm = 'EditForm',
}

class HubsStore {
    formsState: { [key in HubsForms]: boolean } = {
        CreateForm: false,
        EditForm: false,
    };

    isLoading: boolean = false;
    hubs: Hub[] = [];
    hubToEdit: Hub | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    @action
    setHubToEdit(hub: Hub | null) {
        this.hubToEdit = hub;
    }

    async loadHubs() {
        this.setLoading(true);
        const result = await request<GetHubsResponse>('/hubs');

        if (result) {
            runInAction(() => {
                this.hubs = result.hubs;
            });
        }
        this.setLoading(false);
    }

    @computed
    getHub(hubId: string) {
        return this.hubs.find((hub) => hub.id == hubId);
    }

    async addHub(params: CreateHubRequest) {
        const result = await request<Hub>('/hubs', {
            method: 'POST',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadHubs();
        }
    }

    async updateHub(hubId: string, params: UpdateHubRequest) {
        const result = await request<Hub>(`/hubs/${hubId}`, {
            method: 'PUT',
            data: {
                ...params,
            },
        });

        if (result) {
            this.loadHubs();
        }
    }

    async deleteHub(hubId: string) {
        const result = await request<boolean>(`/hubs/${hubId}`, {
            method: 'DELETE',
        });

        if (result) {
            this.loadHubs();
        }
    }

    @action
    openForm(type: keyof typeof HubsForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof HubsForms) {
        this.formsState[type] = false;
    }
}

export default HubsStore;
