import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from '../types/types';
import { spacing } from '@material-ui/system';
import {
    CssBaseline,
    Hidden,
    Paper as MuiPaper,
    withWidth,
} from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import { SnackbarComponent } from '../components/Snackbars';
import Header from '../components/Header';
import Footer from '../components/Footer';

const drawerWidth = 280;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }


  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up('md')} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }

    border-radius: 0 10px 10px 0 !important;
`;

const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.body.background};
    box-shadow: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

type DashboardPropsType = {
    //route: any;
    //width: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

const LayoutWithMenu: React.FC<DashboardPropsType> = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <Drawer>
                <Hidden implementation='js'>
                    <Sidebar
                        PaperProps={{
                            style: {
                                width: drawerWidth,
                                borderRadius: `0 10px 10px 0`,
                            },
                        }}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                    />
                </Hidden>
                <Hidden smDown implementation='css'>
                    <Sidebar
                        PaperProps={{
                            style: {
                                width: drawerWidth,
                                borderRadius: `0 10px 10px 0`,
                            },
                        }}
                    />
                </Hidden>
            </Drawer>
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                {/*<LogItemsModalComponent />*/}
                {/*<ChatModalComponent />*/}
                <SnackbarComponent />
                <MainContent p={5}>
                    <React.Suspense fallback={<>...</>}>
                        <Outlet />
                    </React.Suspense>
                </MainContent>
                <Footer />
            </AppContent>
            {/*<Settings />*/}
        </Root>
    );
};

export default withWidth()(LayoutWithMenu);
